module.exports = {
    list_type: [
        "anime_list", "currently_airing", "featured", "filter", "last_added_movie",
        "last_added_tv", "latest_episodes", "latest_updated_episode", "top_anime", "top_anime_mal",
        "top_currently_airing", "top_currently_airing_mal", "top_movie",
        "top_movie_mal", "top_tv", "top_tv_mal", "top_upcoming", "top_upcoming_catalog"
    ],
    _order_by: [
        "anime_name_asc", "anime_name_desc", "anime_rating_desc", "anime_year_asc",
        "anime_year_desc", "best_match", "earliest_first", "latest_first", "mal_rank_asc"
    ],
    _limit: [
        "10", "25", "50", "100"
    ],
    anime_genres: [
        { "label": "اكشن", "value": "1" },
        { "label": "مغامرات", "value": "2" },
        { "label": "سيارات", "value": "3" },
        { "label": "كوميديا", "value": "4" },
        { "label": "جنون", "value": "5" },
        { "label": "شياطين", "value": "6" },
        { "label": "غموض", "value": "7" },
        { "label": "دراما", "value": "8" },
        { "label": "ايتشي", "value": "9" },
        { "label": "خيال", "value": "10" },
        { "label": "العاب", "value": "11" },
        { "label": "تاريخي", "value": "12" },
        { "label": "رعب", "value": "13" },
        { "label": "اطفال", "value": "14" },
        { "label": "سحر", "value": "15" },
        { "label": "فنون قتالية", "value": "16" },
        { "label": "ميكا", "value": "17" },
        { "label": "موسيقى", "value": "18" },
        { "label": "محاكاة ساخرة", "value": "19" },
        { "label": "ساموراي", "value": "20" },
        { "label": "رومانسي", "value": "21" },
        { "label": "مدرسي", "value": "22" },
        { "label": "خيال علمي", "value": "23" },
        { "label": "شوجو", "value": "24" },
        { "label": "شونين", "value": "25" },
        { "label": "فضاء", "value": "26" },
        { "label": "رياضي", "value": "27" },
        { "label": "قوى خارقة", "value": "28" },
        { "label": "مصاص دماء", "value": "29" },
        { "label": "حريم", "value": "30" },
        { "label": "شريحة من الحياة", "value": "31" },
        { "label": "خارق للطبيعة", "value": "32" },
        { "label": "عسكري", "value": "33" },
        { "label": "بوليسي", "value": "34" },
        { "label": "نفسي", "value": "35" },
        { "label": "اثارة", "value": "36" },
        { "label": "سينين", "value": "37" },
        { "label": "جوسي", "value": "38" },
    ],
    anime_studio_ids: [
        { "label": "Toei Animation", "value": "2" },
        { "label": "Tokyo Movie Shinsha", "value": "3" },
        { "label": "Studio Ghibli", "value": "4" },
        { "label": "Lay-duce", "value": "5" },
        { "label": "Nippon Animation", "value": "6" },
        { "label": "Tatsunoko Production", "value": "7" },
        { "label": "Group TAC", "value": "8" },
        { "label": "Studio Pierrot", "value": "9" },
        { "label": "Studio Hibari", "value": "10" },
        { "label": "Sunrise", "value": "12" },
        { "label": "Gallop", "value": "13" },
        { "label": "Studio Deen", "value": "15" },
        { "label": "Artland", "value": "17" },
        { "label": "Production Reed", "value": "19" },
        { "label": "Gainax", "value": "20" },
        { "label": "J.C.Staff", "value": "22" },
        { "label": "Production I.G", "value": "23" },
        { "label": "Madhouse", "value": "24" },
        { "label": "AIC", "value": "27" },
        { "label": "Xebec", "value": "28" },
        { "label": "OLM", "value": "29" },
        { "label": "TMS Entertainment", "value": "30" },
        { "label": "Studio Comet", "value": "31" },
        { "label": "Brain's Base", "value": "33" },
        { "label": "Bee Train", "value": "35" },
        { "label": "Hal Film Maker", "value": "38" },
        { "label": "Gonzo", "value": "39" },
        { "label": "Bones", "value": "41" },
        { "label": "Shaft", "value": "42" },
        { "label": "Satelight", "value": "45" },
        { "label": "A.C.G.T.", "value": "46" },
        { "label": "Kyoto Animation", "value": "47" },
        { "label": "feel.", "value": "48" },
        { "label": "Zexcs", "value": "49" },
        { "label": "Telecom Animation Film", "value": "51" },
        { "label": "ufotable", "value": "52" },
        { "label": "Manglobe", "value": "53" },
        { "label": "Studio 4°C", "value": "54" },
        { "label": "Nomad", "value": "55" },
        { "label": "SynergySP", "value": "57" },
        { "label": "Graphinica", "value": "64" },
        { "label": "Seven Arcs", "value": "68" },
        { "label": "CoMix Wave Films", "value": "70" },
        { "label": "Doga Kobo", "value": "71" },
        { "label": "Shirogumi", "value": "72" },
        { "label": "Actas", "value": "74" },
        { "label": "Ajia-Do", "value": "75" },
        { "label": "A-1 Pictures", "value": "82" },
        { "label": "Diomedea", "value": "84" },
        { "label": "Asread", "value": "85" },
        { "label": "P.A. Works", "value": "87" },
        { "label": "Seven", "value": "90" },
        { "label": "Kinema Citrus", "value": "92" },
        { "label": "David Production", "value": "93" },
        { "label": "Silver Link.", "value": "95" },
        { "label": "Polygon Pictures", "value": "98" },
        { "label": "White Fox", "value": "101" },
        { "label": "Bridge", "value": "102" },
        { "label": "Lerche", "value": "108" },
        { "label": "Project No.9", "value": "111" },
        { "label": "TYO Animations", "value": "113" },
        { "label": "DLE", "value": "114" },
        { "label": "Pierrot Plus", "value": "115" },
        { "label": "M.S.C", "value": "116" },
        { "label": "Asahi Production", "value": "117" },
        { "label": "Studio Gokumi", "value": "118" },
        { "label": "Passione", "value": "119" },
        { "label": "Shin-Ei Animation", "value": "121" },
        { "label": "SANZIGEN", "value": "123" }, { "label": "8bit", "value": "124" },
        { "label": "Bandai Namco Pictures", "value": "125" },
        { "label": "GoHands", "value": "126" },
        { "label": "Hoods Entertainment", "value": "130" },
        { "label": "Tezuka Productions", "value": "131" },
        { "label": "MAPPA", "value": "132" },
        { "label": "Production IMS", "value": "133" },
        { "label": "LIDENFILMS", "value": "134" },
        { "label": "Seven Arcs Pictures", "value": "135" },
        { "label": "Trigger", "value": "137" },
        { "label": "Orange", "value": "138" },
        { "label": "Wit Studio", "value": "144" },
        { "label": "ILCA", "value": "145" },
        { "label": "C2C", "value": "146" },
        { "label": "Connect", "value": "149" },
        { "label": "Studio 3Hz", "value": "152" },
        { "label": "Shuka", "value": "153" },
        { "label": "EMT Squared", "value": "155" },
        { "label": "Haoliners Animation League", "value": "158" },
        { "label": "Creators in Pack", "value": "162" },
        { "label": "Signal.MD", "value": "164" },
        { "label": "C-Station", "value": "165" },
        { "label": "Zero-G", "value": "173" },
        { "label": "Fanworks", "value": "183" },
        { "label": "CloverWorks", "value": "192" },
        { "label": "Science SARU", "value": "236" },
        { "label": "Studio Kai", "value": "244" },
        { "label": "Sparkly Key Animation Studio", "value": "363" }
    ]
}